<template>
    <v-form v-model="isValid">
        <div class="txt txt--sm text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>

        <vertical-form-table v-model="form" v-bind="{ items }" @input="emit">
            <template slot="비밀번호 확인">
                <v-password-confirm-field :password="form.password" outlined />
            </template>
        </vertical-form-table>

        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <v-btn tile v-bind="{ ...btn_primary, ...loading, disabled }" class="v-btn--width-fixed v-size--xx-large w-100 min-w-sm-200px" @click="$emit('save')">가입완료</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import { attrs_input, btn_primary, initUser, rules } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
{
        key: "username",
        term: "아이디",
        type: "text",
        rules: rules.username,
        required: true,
        placeholder: "공백 없는 영문/숫자 포함 6~20자",
        ...attrs_input,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        rules: rules.password,
        required: true,
        ...attrs_input,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
        ...attrs_input,
    },
    {
        key: "companyName",
        term: "업체명",
        type: "text",
        required: true,
        rules: rules.companyName,
        ...attrs_input,
    },
    {
        key: "ceoName",
        term: "대표자명",
        type: "text",
        required: true,
        rules: rules.ceoName,
        ...attrs_input,
    },
    {
        key: "registrationNumber",
        term: "사업자등록번호",
        type: "text",
        required: true,
        rules: rules.registrationNumber,
        ...attrs_input,
    },
    {
        key: "businessRegistration",
        term: "사업자 등록증 사본",
        type: "file",
        required: true,
        accept: ["image/*", "application/pdf"],
        rules: rules.businessRegistration,
        ...attrs_input,
    },
    {
        key: "licenseCode",
        term: "통신판매업 번호",
        type: "text",
        required: true,
        rules: rules.licenseCode,
        ...attrs_input,
    },
    {
        key: "address",
        term: "사업장 주소",
        type: "address",
        // rules: rules.address,
        required: true,
        ...attrs_input,
    },
    {
        key: "name",
        term: "담당자 명",
        type: "text",
        required: true,
        disabled: true,
        ...attrs_input,
    },
    {
        key: "department",
        term: "담당자 부서명",
        type: "text",
        // rules: rules.,
        ...attrs_input,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        rules: rules.email,
        required: true,
        ...attrs_input,
    },
    {
        key: "tel",
        term: "전화번호",
        type: "phone",
        ...attrs_input,
    },
    {
        key: "phone",
        term: "휴대폰 번호",
        type: "phone",
        // rules: rules.phone,
        required: true,
        disabled: true,
        ...attrs_input,
    },
    {
        key: "referrer",
        term: "추천인 ID",
        type: "text",
        // rules: rules.,
        ...attrs_input,
    },
];

export default {
    components: {
        VerticalFormTable,
        VPasswordConfirmField
    },
    props: {
        value: { type: Object, default: initUser },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initUser(),

        items,
        btn_primary,
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUser(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
